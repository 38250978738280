var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"wrapper",attrs:{"show":_vm.loading}},[(_vm.activeLayer)?_c('Calendar',{attrs:{"calendar":_vm.calendar,"date":_vm.date},scopedSlots:_vm._u([{key:"frontRow",fn:function(ref){
var rowIndex = ref.rowIndex;
return [(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].name)+" ")]):_vm._e(),(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].startTime)+" - "+_vm._s(_vm.config.layers[rowIndex].endTime)+" ")]):_vm._e()]}},{key:"default",fn:function(ref){
var context = ref.context;
return [(
          context.indexConfig.groupIndex !== null &&
          _vm.config.groups[context.indexConfig.groupIndex]
        )?_c('div',[_c('div',{staticClass:"status-bar",style:(("--bg-color: " + (_vm.config.groups[context.indexConfig.groupIndex].color) + "; color: " + (_vm.getColorOfText(
            _vm.config.groups[context.indexConfig.groupIndex].color
          ))))},[_vm._v(" "+_vm._s(_vm.config.groups[context.indexConfig.groupIndex].name)+" ")]),_c('div',{staticClass:"entries"},_vm._l((context.value),function(entry){return _c('div',{key:entry.insertedIndex},[_c('div',{staticClass:"d-flex"},[_c('Status',{attrs:{"status":_vm.displayStatus(context, entry)}}),_c('span',{staticClass:"user-select-none count"},[_vm._v(" "+_vm._s(entry.insertedIndex + 1)+". ")]),(entry.type)?_c('span',{staticClass:"drag-zone-empty"},[_vm._v(" "+_vm._s(_vm.display(context, entry))+" ")]):_vm._e()],1)])}),0)]):_c('div')]}}],null,false,2240172288)}):_vm._e(),_c('Controllers',{attrs:{"active-layer-populated":_vm.activeLayerPopulated,"date":_vm.date,"layers":_vm.layers},on:{"set-date":_vm.setDate,"set-layer":_vm.setLayer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }