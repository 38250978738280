<template>
  <div>
    <div class="control-center">
      <b-button-group>
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :text="dropDownText"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="layer in layers"
            :key="layer.id"
            @click="setActiveLayer(layer.id)"
            >{{ layer.area }}</b-dropdown-item>
        </b-dropdown>
        <b-button
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          variant="outline-primary"
          :disabled="prevDisabled"
          @click="prevWeek"
        >
          <feather-icon icon="ChevronsLeftIcon" />
        </b-button>
        <b-form-datepicker
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          :value="new Date(date)"
          v-bind="$t('Bootstrap.DatePicker')"
          button-only
          right
          :min="datePickerMin"
          :start-weekday="1"
          locale="de-De"
          button-variant="outline-primary"
          @context="onDatepicker"
        />
        <b-button
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          variant="outline-primary"
          @click="nextWeek"
        >
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-button-group>
    </div>
    <div class="execute">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        class="mr-1"
        @click="goBack"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="print"
      >
        {{ $t('StaffScheduling.Print') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BButtonGroup,
    BButton,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
  },

  props: {
    activeLayerPopulated: {
      type: Object,
      default: () => null,
    },
    date: {
      type: Number,
      default: () => 0,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  computed: {
    layersDictionary() {
      const dictionary = {}

      this.layers.forEach(x => {
        dictionary[x.id] = x
      })

      return id => dictionary[id]
    },

    datePickerMin() {
      if (!this.activeLayerPopulated) return null
      return moment(
        this.activeLayerPopulated.configuration.startDate,
        'YYYY-MM-DD',
      ).toDate()
    },

    dropDownText() {
      if (this.activeLayerPopulated === null) {
        return this.$t('Global.Select', {
          subject: this.$t('Management.Layers.Layers'),
        })
      }
      return this.activeLayerPopulated.area
    },

    prevDisabled() {
      if (!this.activeLayerPopulated) return true
      const startDate = moment(
        this.activeLayerPopulated.configuration.startDate,
        'YYYY-MM-DD',
      )
      const date = moment(this.date)

      return startDate.startOf('week').add(1, 'day').isSameOrAfter(date)
    },
  },

  methods: {
    onDatepicker({ selectedDate }) {
      const timeStamp = moment(selectedDate).valueOf()
      if (this.date === 0 || timeStamp === this.date) return
      this.$emit('set-date', timeStamp)
    },

    setActiveLayer(layer) {
      const id = this.activeLayerPopulated ? this.activeLayerPopulated.id : null
      if (id !== layer) {
        this.$emit('set-layer', layer)
      }
    },

    goBack() {
      this.$router.back()
    },

    prevWeek() {
      this.$emit('set-date', moment(this.date).subtract(1, 'week').valueOf())
    },
    nextWeek() {
      this.$emit('set-date', moment(this.date).add(1, 'week').valueOf())
    },

    print() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
.execute {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.control-center {
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  background-color: #f8f8f8;
  display: flex;
}

.select {
  min-width: 200px;
}

@media print {
  .execute,
  .control-center {
    display: none !important;
  }
}
</style>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

@media print {
  html,
  body,
  #app {
    height: auto !important;
  }
}
</style>
