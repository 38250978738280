<template>
  <b-overlay :show="loading" class="wrapper">
    <Calendar v-if="activeLayer" :calendar="calendar" :date="date">
      <template #frontRow="{ rowIndex }">
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].name }}
        </div>
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].startTime }}
          -
          {{ config.layers[rowIndex].endTime }}
        </div>
      </template>
      <template v-slot="{ context }">
        <div
          v-if="
            context.indexConfig.groupIndex !== null &&
            config.groups[context.indexConfig.groupIndex]
          "
        >
          <div
            class="status-bar"
            :style="`--bg-color: ${
              config.groups[context.indexConfig.groupIndex].color
            }; color: ${getColorOfText(
              config.groups[context.indexConfig.groupIndex].color,
            )}`"
          >
            {{ config.groups[context.indexConfig.groupIndex].name }}
          </div>
          <div class="entries">
            <div v-for="entry in context.value" :key="entry.insertedIndex">
              <div class="d-flex">
                <Status :status="displayStatus(context, entry)" />
                <span class="user-select-none count">
                  {{ entry.insertedIndex + 1 }}.
                </span>
                <span v-if="entry.type" class="drag-zone-empty">
                  {{ display(context, entry) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else />
      </template>
    </Calendar>
    <Controllers
      :active-layer-populated="activeLayerPopulated"
      :date="date"
      :layers="layers"
      @set-date="setDate"
      @set-layer="setLayer"
    />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import moment from 'moment'

import contrastColor from '@/utility/functions/contrastColor'

import Calendar from './components/content/calendar.vue'
import Controllers from './components/pdf/controllers.vue'
import Status from './components/content/status.vue'
import { load, loadCalendar, loadEmployees } from './utility/load'
import { display, displayStatus } from './utility/handleDragDrop'

export default {
  components: {
    BOverlay,
    Calendar,
    Controllers,
    Status,
  },

  data: () => ({
    loading: false,
    date: 0,
    activeLayer: null,

    calendar: [],
    calendarCache: {},

    employees: [],
    employeesCache: {},
    serviceProviders: [],
    layers: [],
  }),

  computed: {
    config() {
      return this.activeLayerPopulated.configuration
    },
    activeLayerPopulated() {
      return this.layers.find(x => x.id === this.activeLayer)
    },
  },

  async created() {
    this.loading = true
    await Promise.all([
      this.loadEmployees(),
      this.load('serviceProviders', 'service-providers'),
      this.load('layers', 'layers/my-responsibilities'),
    ])

    if (this.layers.find(x => x.id === this.$route.params.layer)) {
      this.activeLayer = this.$route.params.layer
    } else if (this.layers.length !== 0) {
      this.activeLayer = this.layers[0].id
    }

    await this.$nextTick()
    const timestamp = Math.abs(parseInt(this.$route.params.timestamp, 10))
    this.date = Math.max(
      timestamp,
      moment(this.config.startDate, 'YYYY-MM-DD').valueOf(),
    )

    await this.loadCalendar()
    this.loading = false
  },

  methods: {
    async setDate(value) {
      this.loading = true
      this.date = value
      this.$router.replace({ params: { timestamp: value } })
      await this.loadCalendar()
      this.loading = false
    },
    async setLayer(layer) {
      this.loading = true
      this.activeLayer = layer
      this.$router.replace({ params: { layer } })
      await this.loadCalendar()
      this.loading = false
    },

    findActiveValues(_, entry) {
      return entry
    },

    getColorOfText(hexcolor) {
      return contrastColor(hexcolor)
    },

    async load(...args) {
      return load.call(this, ...args)
    },

    async loadCalendar(...args) {
      return loadCalendar.call(this, ...args)
    },

    async loadEmployees(...args) {
      return loadEmployees.call(this, ...args)
    },

    display(...args) {
      return display.call(this, ...args)
    },

    displayStatus(...args) {
      return displayStatus.call(this, ...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-bar {
  width: 100%;
  height: 20px;
  padding-left: 0.5rem;
  position: relative;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
    background-color: var(--bg-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
  }
}

.drag-zone-empty {
  flex: 1;
  border: 2px transparent solid;
  padding-left: 4px;
  border-radius: 1rem;
  transition: all 500ms ease-in-out;
}

.entries {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
}

.count {
  min-width: 1rem;
  align-self: center;
}

@media print {
  @page {
    margin: 0;
    size: landscape;
  }

  body {
    margin-inline: 0;
    margin-block: 0;
  }

  * {
    font-size: 10px;
  }

  .status-bar {
    height: 15px;

    &::after {
      height: 15px;
    }
  }

  .drag-zone-empty {
    border: none;
    padding-left: 4px;
    border-radius: 0;
    transition: none;
  }
}
</style>
